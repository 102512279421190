import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import {useRef} from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_s0g1exb', 'template_owc372u', form.current, '2AJ-ZQC0NKEOs8Rdh')

        e.target.reset()
    };

    return (
        <section id='contact'>
            <h5>Get In Touch</h5>
            <h2>Contact Me</h2>

            <div className="container contact__container">
                <div className="contact__options">
                    <article className='contact__option'>
                        <MdOutlineEmail className='contact__option-icon'/>
                        <h4>Email</h4>
                        <h5>akiofujita0@gmail.com</h5>
                        <a href='mailto:akiofujita0@gmail.com' target='_blank'>Send a Message</a>
                    </article>


                    {/* <article className='contact__option'>
                        <RiMessengerLine className='contact__option-icon'/>
                        <h4>Messenger</h4>
                        <h5>+19253608641</h5>
                        <a href='https://wa.me/+19253608641' target='_blank'>Send a Message</a>
                    </article> */}

                    <article className='contact__option'>
                        <BsWhatsapp className='contact__option-icon'/>
                        <h4>WhatsApp</h4>
                        <h5>+19253608641</h5>
                        <a href='https://wa.me/+19253608641' target='_blank'>Send a Message</a>
                    </article>
                </div>

                <form ref={form} onSubmit={sendEmail}>
                    <input type='text' name='name' placeholder='Your Full Name' required/>
                    <input type='email' name='email' placeholder='Your Email' required/>
                    <textarea name='message' rows='7' placeholder='Your Message' required></textarea>
                    <div className='contact-btn-container'>
                        <button type='submit' className='btn btn-primary'>Send Message</button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Contact
