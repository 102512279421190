import React from 'react'
import './about.css'
import ME from '../../assets/me-about.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
    return (
        <section id='about'>
            <h5>Get To Know</h5>
            <h2>About Me</h2>

            <div className='container about__container'>
                <div className='about__me'>
                    <div className='about__me-image'>
                        <img src={ME} alt='About Me' />
                    </div>
                </div>

                <div className='about__content'>
                    <div className='about__cards'>
                        <article className='about__card'>
                            <FaAward className='about__icon'/>
                            <h5>Experience</h5>
                            <small>1+ Years Working</small>
                        </article>

                        <article className='about__card'>
                            <FiUsers className='about__icon'/>
                            <h5>Clients</h5>
                            <small>Coming Soon!</small>
                        </article>

                        <article className='about__card'>
                            <VscFolderLibrary className='about__icon'/>
                            <h5>Projects</h5>
                            <small>4 Completed</small>
                        </article>
                    </div>

                    <p>
                    Hi! I'm a recent computer engineering graduate from Purdue University, class of Fall 2022.
                    With a solid foundation in both hardware and software, I have a keen interest in the dynamic field of software engineering. 
                    I'm always excited to explore the latest technologies and strive to create innovative solutions that make a positive impact.
                    I'm excited to connect with fellow professionals, share my experiences, and explore new opportunities in the software engineering industry. Feel free to explore my website further to learn more about my skills, projects, and achievements. Let's connect and collaborate on exciting ventures that push the boundaries of technology!
                    </p>

                    <div className='about-btn-container'>
                        <a href="#contact" className='btn btn-primary'>Let's Talk</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
