import React from 'react'
import './footer.css'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'

const Footer = () => {
    return (
        <footer>
            <a href="#" className='footer__logo'>Akio Fujita</a>

            <ul className='permalinks'>
                <li><a href='#'>Home</a></li>
                <li><a href='#about'>About</a></li>
                <li><a href='#experience'>Experience</a></li>
                <li><a href='#services'>Services</a></li>
                <li><a href='#portfolio'>Portfolio</a></li>
                <li><a href='#contact'>Contact</a></li>
            </ul>

            <div className='footer__socials'>
                <a href='https://www.instagram.com/akio__fujita/?igshid=OGQ5ZDc2ODk2ZA%3D%3D'><FiInstagram/></a>
                <a href='https://twitter.com/akiofujita13?s=11&t=iQ9mzj28N9SmPM8QgWjyhg'><IoLogoTwitter/></a>
            </div>

            <div className='footer__copyright'>
                <small>&copy; Akio Fujita. All rights reserved.</small>
            </div>
        </footer>
    )
}

export default Footer
